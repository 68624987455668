<template>
  <div>
    <Header />
    <main class="md:flex flex-wrap min-h-screen text-center">
        <div id="dib-posts" class="mx-auto w-full"></div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Vue from "vue";

export default {
  name: "Blog",
  components: {
    Header,
    Footer,
  },
  mounted() {

    const alreadyReloaded = localStorage.getItem('blogInit', true);
    if(alreadyReloaded) {
      localStorage.removeItem('blogInit');
    } else {
      localStorage.setItem('blogInit', true)
      window.location.reload();
    }
    Vue.loadScript("https://io.dropinblog.com/embedjs/848ea8f7-6fe5-4c58-959a-56ee1bd460e8.js")
    .then(() => {
      // Script is loaded, do something
    })
    .catch(() => {
      // Failed to fetch script
    });

  },
  computed: {},
  methods: {
   
  },
  watch: {
    '$route.params'() {
      window.location.reload();
      console.log(this.$route.params);
    }
  }
};
</script>
